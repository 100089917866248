@import url('https://fonts.googleapis.com/css2?family=Righteous&family=Roboto+Condensed&display=swap');
@import-normalize;
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  /* width */
  ::-webkit-scrollbar {
    @apply w-1;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    @apply bg-[rgba(0,0,0,0.4)];
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    @apply bg-zinc-300 dark:bg-slate-500;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    @apply bg-zinc-200 dark:bg-slate-400;
  }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto Condensed', sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

#bar::-webkit-scrollbar {
  display: none;
}
